<template>
  <div id="sign-in" class="auth">
    <!-- <h1 class="header">Sign In</h1> -->
    <form class="auth-form" @submit.prevent="signInAndRedirect">
      <fieldset :disabled="loading">
        <div class="input-group">
          <input
            v-model="email"
            type="email"
            placeholder=" "
            id="email"
            required
            autocomplete="email"
          />
          <label for="email">Email</label>
          <p class="error-info">Please enter a valid email</p>
        </div>
        <div class="input-group">
          <input
            v-model="password"
            type="password"
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
            placeholder=" "
            id="password"
            autocomplete="current-password"
            required
          />
          <label for="password">Password</label>
          <p class="error-info">
            Your password must contain at least one uppercase letter, one
            lowercase letter and one number and be at least 8 characters long
          </p>
        </div>
        <button type="submit" :class="{ loading }">
          <template v-if="!loading"> Sign in to NRoom </template>
        </button>
      </fieldset>
      <p>{{ errorMessage }}</p>

      <div class="forgot-password">
        <router-link :to="{ name: 'ForgotPassword' }"
          >Forgot your password?</router-link
        >
      </div>
    </form>
    <!-- <div class="login-signup-now">
      New to NRoom?
      <router-link :to="{ name: 'SignUp' }">Sign up now</router-link>.
    </div> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SignIn",
  data() {
    return {
      password: "",
      email: "",
      redirect: {
        name: "Scripts",
      },
      loading: false,
      errorMessage: null,
    };
  },
  methods: {
    ...mapActions(["signIn"]),
    async signInAndRedirect() {
      this.loading = true;
      try {
        await this.signIn({ email: this.email, password: this.password });
        // this.$store.dispatch("fetchUserProfile");
        this.$router.push(this.redirect);
      } catch (err) {
        this.errorMessage = err.message;
        alert(err.message);
        throw new Error(err);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    const route = this.$route.query;
    if (route?.name)
      this.redirect = {
        name: route?.name,
        params: JSON?.parse?.(route.params),
      };
  },
};
</script>

<style lang="scss" scoped>
#sign-in {
  color: white;

  .forgot-password {
    color: white;
    text-align: center;
    font-size: 1.3rem;
    margin-top: 20px;
  }
}

.header {
  font-size: 3.2rem;
  font-weight: 700;
  margin-bottom: 28px;
}
</style>
