<template>
  <div>
    <div class="profile-title">
      <span>Recent Purchases</span>
    </div>
    <div class="purchases">
      <div v-for="purchase in purchases" :key="purchase.id" class="purchase">
        <img
          :src="purchase.poster_path"
          :alt="purchase.name || purchase.title"
        />
        <div class="overlay"></div>
        <h2 class="purchase-name">{{ purchase.name || purchase.title }}</h2>
        <p class="purchase-type">
          {{ purchase.media_type }}
        </p>
        <!-- <router-link
          :to="{ name: 'PDF', params: { pdf: playPDF } }"
          class="btn"
          @click.native="openFullscreen"
          >Read More</router-link
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import { scriptCollections, auth } from "@/firebase-config";

export default {
  name: "Purchases",
  data() {
    return {
      purchases: [],
      loading: false,
    };
  },
  methods: {
    async getPurchases() {
      this.loading = true;
      try {
        const ref = scriptCollections.where(
          "owner",
          "==",
          auth.currentUser.uid
        );
        await ref.onSnapshot(
          (querySnapshot) => {
            if (!querySnapshot.empty) {
              let play = querySnapshot.docChanges()[0].doc.data();
              play.id = querySnapshot.docChanges()[0].doc.id;
              if (querySnapshot.docChanges()[0].type == "modified") {
                this.managePurchases(play, "modified");
              } else if (querySnapshot.docChanges()[0].type == "removed") {
                this.managePurchases(play, "removed");
              } else {
                querySnapshot.docs.forEach((doc) => {
                  let data = doc.data();
                  data.id = doc.id;
                  this.purchases.push(data);
                });
              }
            } else this.loading = false;
          },
          function (error) {
            throw error;
          }
        );
      } catch (err) {
        throw new Error(err);
      } finally {
        this.loading = false;
      }
    },
    managePurchases(data, action) {
      const playIndexToModify = this.purchases.findIndex(
        (play) => play.id === data.id
      );
      if (playIndexToModify < 0) return;

      switch (action) {
        case "modified":
          this.$set(this.purchases, playIndexToModify, data);
          break;
        case "removed":
          this.$delete(this.purchases, playIndexToModify);
          break;
      }
    },
  },
  beforeMount() {
    this.getPurchases();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters.isAdmin) next({ name: "ManagePlays" });
    });
  },
};
</script>

<style lang="scss" scoped>
$break-point: 1024px;

.profile-title {
  margin-top: 0;
}

.purchases {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 5px;

  @media (min-width: $break-point) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .purchase {
    position: relative;
    overflow: hidden;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      background: black;
      opacity: 0;
      top: 0;
      left: 0;
      transition: 0.4s opacity ease;
    }

    .purchase-name {
      position: absolute;
      bottom: 0px;
      width: 100%;
      transform-origin: top left;
      transform: rotate(-90deg);
      font-weight: 400;
      transition: 0.4s all ease-in-out;
      left: -30%;
    }

    .purchase-type {
      border: 2px solid white;
      padding: 2px 5px;
      text-transform: capitalize;
      transition: 0.4s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
      position: absolute;
      top: -100px;
      right: 10px;
      font-size: 1.2rem;
    }

    &:hover {
      .overlay {
        opacity: 0.8;
      }

      .purchase-name {
        left: 10px;
      }

      .purchase-type {
        top: 10px;
      }
    }
  }
}
</style>
